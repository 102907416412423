<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add/Edit Video Presentation</h1>
    </page-header>

    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h4 v-if="this.visit.location_org_key">
          {{ location.org_name }}
          <div class="change-link" @click="showFacilityDropdown">{{ translations.tcChangeFacility }}</div>
        </h4>
        <h1 class="sm mb-4">{{ translations.tcAddEditGideonCardVideoPresentation }}</h1>
        <div v-if="!this.visit.location_org_key" class="col-6">
          <div class="date-select-1 mb-1">
            <label class="font-style-1">{{ translations.tcSelectAFacility }}</label>
          </div>
          <QuickJump @facility_changed="handleFacilityChange()" :i18n="translations.components"></QuickJump>
        </div>
        <div class="body" v-if="this.visit.location_org_key">
          <b-form>
            <div class="row">
              <div class="col-12">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <h3 class="font-style-2">{{ translations.tcDate }} *</h3>
                  </div>
                  <div class="date-select-1 mb-3">
                    <b-form-datepicker
                      id="presentationDate"
                      v-model="visit.date"
                      class="mb-2 contact_date"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>

      <section class="section-3b bg-block mb-4" v-if="this.visit.location_org_key">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
        </header>
        <div class="body">
          <b-form>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <h3 class="font-style-2">{{ translations.tcContactedBy }} *</h3>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select
                        :title="translations.tcContactedBy"
                        v-model="visit.cord_ind_key"
                        :options="contactOption"
                        class="g-select flex-0 mr-3 mb-3"
                        required
                      ></b-form-select>
                      <b-button
                        :name="`contactedByFindButton`"
                        variant="primary"
                        class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal', 'coordinator')"
                        >{{ translations.tcFind }}</b-button
                      >
                      <template slot="first">
                        <option :value="null" disabled>{{ translations.tcSelect }}</option>
                      </template>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <h3 class="font-style-2">{{ translations.tcPresenter }} *</h3>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select
                        :title="translations.tcPresenter"
                        v-model="visit.pres_ind_key"
                        :options="presenterOption"
                        class="g-select flex-0 mr-3 mb-3"
                        required
                      ></b-form-select>
                      <b-button
                        :name="`presenterFindButton`"
                        variant="primary"
                        class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal', 'presenter')"
                        >{{ translations.tcFind }}</b-button
                      >
                      <template slot="first">
                        <option :value="null" disabled>{{ translations.tcSelect }}</option>
                      </template>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>
      <section class="section-4 bg-block mb-4" v-if="this.visit.location_org_key">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="visit.note" class="w-100"></textarea>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4" v-if="this.visit.location_org_key">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :cmp_org_key="this.campKey"
      :modal_title="modal_title"
    ></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import QuickJump from '@/components/quick-jump/QuickJumpGideonCards.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'card_video_presentation_add',
  mixins: [translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      findButton: '',
      selectChurch: [],
      searchedContact: [],
      contactOption: [],
      presenterOption: [],
      currentSelection: '',
      visit: {
        host_org_key: '',
        date: this.formatForDatePicker(new Date()),
        mts_key: '',
        location_org_key: '',
        cord_ind_key: '',
        pres_ind_key: '',
        note: '',
        mtg_key: '',
        mxi_key: null,
      },
      modal_title: 'Individual Search',
    }
  },
  methods: {
    ...mapActions({
      clearFacilityDetails: 'card/clearFacilityDetails',
      clearVideoVisitDetails: 'card/clearVideoVisitDetails',
      getVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      loadMembersByCamp: 'membership/loadMembersByCamp',
      loadProfile: 'card/getProfile',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
      upsertVideoVisit: 'card/upsertVideoVisit',
    }),
    showModal(e, modalName, selection) {
      this.currentSelection = selection
      this.findButton = !e.target ? e : e.target.name
      switch (this.findButton) {
        case 'contactedByFindButton':
          this.modal_title = this.translations.tcContactedBySearch
          break
        case 'presenterFindButton':
          this.modal_title = this.translations.tcPresenterSearch
          break
        default:
          this.modal_title = this.translations.tcIndividualSearch
          break
      }
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    applyFoundPerson($event) {
      if (this.currentSelection == 'coordinator') {
        this.visit.cord_ind_key = $event.ind_key
        let option = { value: $event.ind_key, text: $event.name_text }
        this.contactOption.push(option)
      } else if (this.currentSelection == 'presenter') {
        this.visit.pres_ind_key = $event.ind_key
        let option = { value: $event.ind_key, text: $event.name_text }
        this.presenterOption.push(option)
      }
    },
    setCordinatorDetail(videoVisitDetails) {
      this.visit.cord_ind_key = videoVisitDetails.meeting.mtg_coordinator_ind_key
      let option = {
        value: videoVisitDetails.meeting.mtg_coordinator_ind_key,
        text: videoVisitDetails.coordinator.formal_name,
      }
      this.contactOption.push(option)
    },
    setPresenterDetail(videoVisitDetails) {
      this.visit.pres_ind_key = videoVisitDetails.meeting.gideoncard_video_presenter_ind_key
      let name = ''
      if (videoVisitDetails.presentationIndividual != null) {
        name = videoVisitDetails.presentationIndividual.formal_name
      }
      let option = {
        value: videoVisitDetails.meeting.gideoncard_video_presenter_ind_key,
        text: name,
      }
      this.presenterOption.push(option)
    },
    setVisitDefault(videoVisitDetails) {
      this.visit.mtg_key = videoVisitDetails.meeting.mtg_key
      this.visit.date = this.formatForDatePicker(videoVisitDetails.meeting.mtg_start_date)
      this.visit.mts_key = videoVisitDetails.meeting.mtg_mts_key
      this.visit.note = videoVisitDetails.meeting.mtg_note
      this.visit.mxi_key = videoVisitDetails.meeting.gideoncard_video_presenter_mxi_key
      this.setCordinatorDetail(videoVisitDetails)
      this.setPresenterDetail(videoVisitDetails)
    },
    validate() {
      if (this.visit.cord_ind_key == '' || this.visit.pres_ind_key == '' || this.visit.date == null) {
        return false
      }
      return true
    },
    handleSaveClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: `${this.translations.tcDate}, ${this.translations.tcContactedBy} ${this.translations.tcAnd} ${this.translations.tcPresenter} ${this.translations.tcAreRequired}. ${this.translations.tcValidationMessage}.`,
        })
        return
      }
      //Handle default status
      let today = new Date()
      let visdate = new Date(this.visit.date)
      if (this.visit.date === '' || this.visit.date === null || visdate > today) {
        if (this.optionMeetingTypes.length > 0) {
          this.visit.mts_key = this.optionMeetingTypes.find(
            (item) => item.text.toLowerCase().indexOf('pending') > -1
          ).value
        }
      } else {
        this.visit.mts_key = this.optionMeetingTypes.find((item) => item.text.toLowerCase() === 'complete').value
      }

      let data = this.visit
      data.host_org_key = this.userOrgKey
      data.member_key = this.userId
      this.submitRequest(data)
    },
    handleCancelClick() {
      this.$router.push('/programs/gc/profile/card-video-visits-list')
    },
    async submitRequest(data) {
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertVideoVisit(data)
        this.setLoadingStatus(false)
        if (response != '') {
          this.$router.push('/programs/gc/profile/card-video-visits-list')
          return
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: thsi.translations.tcSaveVideoPresentationErrorMessage,
        })
      }
    },
    async handleFacilityChange() {
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      await this.loadProfile(this.userSelectedFacilityKey).then(() => {
        this.pageLoad()
      })
    },
    async showFacilityDropdown() {
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      this.setSelectedFacilityKey(null)
      this.visit = {
        host_org_key: '',
        date: null,
        mts_key: '',
        location_org_key: '',
        cord_ind_key: '',
        pres_ind_key: '',
        note: '',
        mtg_key: '',
        mxi_key: null,
      }
    },
    async pageLoad() {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations('forms'),
        this.getComponentTranslations('quick-jump-gideon-cards'),
        await this.retrieveMeetingTypes(),
        await this.loadMembersByCamp(this.userOrgKey),
        // await this.loadProfile(this.userSelectedFacilityKey),
        !this.userSelectedVisitKey ? null : this.getVideoVisitDetails(this.userSelectedVisitKey),
      ]).then((results) => {
        this.$set(this.translations, 'components', results[1])
        this.visit.location_org_key = this.userSelectedFacilityKey
        this.contactOption = this.members.map((m) => {
          return {
            text: m.name,
            value: m.ind_key,
          }
        })
        this.presenterOption = this.members.map((m) => {
          return {
            text: m.name,
            value: m.ind_key,
          }
        })
        if (Object.keys(this.videoVisitDetails).length > 0 && !!this.userSelectedVisitKey) {
          this.setVisitDefault(this.videoVisitDetails)
        }
        this.setLoadingStatus(false)
      })
    },
  },

  async created() {
    this.pageLoad()
  },
  computed: {
    ...mapGetters({
      campKey: 'user/userCampKey',
      location: 'card/getFacilitySummary',
      meetingTypes: 'card/getMeetingTypes',
      members: 'membership/members',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedVisitKey: 'churchMinistry/selected_visit_key',
      videoVisitDetails: 'card/getVideoVisitDetails',
    }),
    optionMeetingTypes() {
      return this.meetingTypes.map((type) => {
        return { value: type.mts_key, text: type.description }
      })
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    QuickJump: QuickJump,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 45%;
  @include breakpoint(sm) {
    width: 100%;
  }
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
